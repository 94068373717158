import React, { useState, forwardRef, useEffect } from "react";
import DataTable from "react-data-table-component";
import { Col, Dropdown } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import { deletePortalFaq, getPortalFaqs } from "store/actions";
import Loader from "components/shared/Loader";
import { useNavigate } from "react-router-dom";
import { ReactComponent as DotsIcon } from "assets/svgs/three-dots.svg";
import { ReactComponent as EditIcon } from "assets/svgs/edit.svg";
import { ReactComponent as DeleteIcon } from "assets/svgs/delete.svg";
import Swal from "sweetalert2";
import { getId } from "helpers/functions";

const Table = (props) => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const { formatMessage } = useIntl();
  const [rows, setRows] = useState([]);

  useEffect(() => {
    dispatch(getPortalFaqs());

    const timeout = setTimeout(() => {
      setRows(rows);
    }, 2000);
    return () => clearTimeout(timeout);
  }, [dispatch, rows]);

  const BootyCheckbox = new forwardRef(({ onClick, ...rest }, ref) => (
    <div className="form-check">
      <input
        htmlFor="booty-check"
        type="checkbox"
        className="form-check-input"
        ref={ref}
        onClick={onClick}
        {...rest}
      />
      <label className="form-check-label" id="booty-check" />
    </div>
  ));

  // eslint-disable-next-line no-unused-vars
  const [filterText, setFilterText] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const filteredItems = props?.data?.filter(
    (item) =>
      JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !==
      -1
  );

  // const subHeaderComponent = useMemo(() => {
  //   const handleClear = () => {
  //     if (filterText) {
  //       setResetPaginationToggle(!resetPaginationToggle);
  //       setFilterText("");
  //     }
  //   };

  //   return (
  //     <Filter
  //       onFilter={(e) => setFilterText(e.target.value)}
  //       onClear={handleClear}
  //       filterText={filterText}
  //     />
  //   );
  // }, [filterText, resetPaginationToggle]);

  const handleEdit = (id) => {
    navigate(`/portal/faqs/${id}`);
  };

  const handleDelete = (id) => {
    Swal.fire({
      title: formatMessage({ id: "deleteFaq" }),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#2a89f3",
      cancelButtonColor: "#d33",
      confirmButtonText: "نعم",
      cancelButtonText: "لا",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deletePortalFaq(id));
      }
    });
  };

  const columns = [
    {
      name: formatMessage({ id: "title" }),
      selector: (row) => <span>{row?.title}</span>,
      sortable: true,
    },
    {
      name: formatMessage({ id: "reply" }),
      selector: (row) => {
        const reply = row?.reply?.replace(/<\/?[^>]+(>|$)/g, "");
        return (
          <span title={row?.reply}>
            {reply?.substr(0, 50) + (reply?.length > 50 ? "..." : "")}
          </span>
        );
      },
      sortable: true,
    },
    {
      cell: (row) => (
        <div className="global-dropdown">
          <Dropdown>
            <Dropdown.Toggle>
              <DotsIcon />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item onClick={(e) => handleEdit(getId(row))}>
                <EditIcon className="bi bi-pencil edit" />
                تعديل
              </Dropdown.Item>
              <Dropdown.Item onClick={(e) => handleDelete(getId(row))}>
                <DeleteIcon className="bi bi-trash delete" fill="#fff" />
                حذف
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      ),
      allowOverflow: true,
      button: true,
      width: "56px",
    },
  ];

  return (
    <>
      <div className="table-wrap">
        <div className="card">
          <Col xl={6} md={12} xs={12}>
            <div className="card-head">
              <div>
                <h4>
                  <FormattedMessage id="faqs" />
                </h4>
                <p>
                  <FormattedMessage id="faqsManage" />
                </p>
              </div>
            </div>
          </Col>

          <div className="card-body">
            <DataTable
              columns={columns}
              data={filteredItems}
              progressPending={props?.loading}
              progressComponent={<Loader />}
              defaultSortField="name"
              // //selectableRows
              // subHeader
              // subHeaderComponent={subHeaderComponent}
              //selectableRowsComponent={BootyCheckbox}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Table;
