import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSinglePortalReservation } from "store/actions";
import { useParams } from "react-router-dom";
import Loader from "../../components/shared/Loader";
import { FormattedDate, FormattedMessage } from "react-intl";

const ShowReservation = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const { singlePortalReservation, loading } = useSelector(
    (state) => state?.portalReservations
  );

  useEffect(() => {
    dispatch(getSinglePortalReservation(id));
  }, [dispatch, id]);

  if (loading) return <Loader />;
  return (
    <>
      <div className="acc-form">
        <div className="card">
          <div className="card-head">
            <h4>
              <FormattedMessage id="showReservation" />
            </h4>
          </div>
          <div className="card-body">
            <div className="table-responsive">
              <table className="table table-bordered show-table">
                <tbody>
                  <tr>
                    <td>
                      <FormattedMessage id="createdAt" />
                    </td>
                    <td>
                      <FormattedDate
                        value={singlePortalReservation?.createdAt}
                        year="numeric"
                        month="long"
                        day="numeric"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <FormattedMessage id="name" />
                    </td>
                    <td>{singlePortalReservation?.name} </td>
                  </tr>
                  <tr>
                    <td>
                      <FormattedMessage id="email" />
                    </td>
                    <td>
                      <a href={`mailto:${singlePortalReservation?.email}`}>
                        {singlePortalReservation?.email}
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <FormattedMessage id="phone" />
                    </td>
                    <td>
                      <a href={`mailto:${singlePortalReservation?.phone}`}>
                        {singlePortalReservation?.phone}
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <FormattedMessage id="storeType" />
                    </td>
                    <td>
                      <a href={`mailto:${singlePortalReservation?.storeType}`}>
                        {singlePortalReservation?.storeType}
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <FormattedMessage id="storeUrl" />
                    </td>
                    <td>
                      <a
                        href={singlePortalReservation?.storeUrl}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {singlePortalReservation?.storeUrl}
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ShowReservation;
