import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Col, Row } from "react-bootstrap";

import { editCategory, getSingleCategory } from "store/actions";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "components/shared/Loader";
const EditCategory = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const { singleCategory, loading } = useSelector((state) => state?.categories);

  const { register, handleSubmit, reset } = useForm();

  useEffect(() => {
    dispatch(getSingleCategory(id));
  }, [dispatch, id]);

  useEffect(() => {
    reset({
      name: singleCategory?.name,
      // image: singleCategory?.image,
    });
  }, [reset, singleCategory]);

  // const imageChange = async (e) => {
  //   if (e.target.files && e.target.files.length > 0) {
  //     const formData = new FormData();
  //     formData.append("file", e.target.files[0]);
  //     const { data } = await server().post("/upload", formData);
  //     setSelectedImage({
  //       preview: e.target.files[0],
  //       path: data.data.files[0],
  //     });
  //   }
  // };

  // const removeSelectedImage = () => {
  //   setSelectedImage();
  // };

  const onSubmit = (data) => {
    // if (selectedImage && selectedImage.path) data["image"] = selectedImage.path;
    // data.image = "uploads/" + data.image.split("/").pop();
    dispatch(editCategory({ id, data, navigate }));
  };

  if (loading) return <Loader />;
  return (
    <>
      <div className="acc-form">
        <div className="card">
          <div className="card-head">
            <h4>تعديل بيانات التصنيف</h4>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Col xl={10} md={12}>
                <Row>
                  {/* <Col lg={12}>
                    <div className="form-group required">
                      <h5>صورة التصنيف</h5>
                      <div className="image-uploader image-contain">
                        <div className="img-pat">
                          <img
                            src={imageUpload}
                            width={46}
                            height={46}
                            alt=""
                          />
                        </div>
                        <label>
                          {selectedImage && (
                            <div>
                              <Controller
                                control={control}
                                name="image"
                                render={() => {
                                  return (
                                    <img
                                      src={
                                        selectedImage && selectedImage.preview
                                          ? URL.createObjectURL(
                                              selectedImage.preview
                                            )
                                          : singleCategory?.image
                                      }
                                      width={160}
                                      height={160}
                                      alt=""
                                    />
                                  );
                                }}
                              />

                              <button
                                className="removeImg"
                                onClick={removeSelectedImage}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="12"
                                  height="12"
                                  fill="currentColor"
                                  className="bi bi-x-lg"
                                  viewBox="0 0 16 16"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z"
                                  />
                                  <path
                                    fillRule="evenodd"
                                    d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z"
                                  />
                                </svg>
                              </button>
                            </div>
                          )}

                          <input
                            accept="image/*"
                            type="file"
                            onChange={imageChange}
                          />
                        </label>
                      </div>
                    </div>
                  </Col> */}
                  <Col lg={6} xs={12}>
                    <div className="form-group required">
                      <h5>اسم التصنيف بالعربية</h5>
                      <div>
                        <input
                          type="text"
                          className="form-control form-outline"
                          placeholder="اسم التصنيف بالعربية"
                          {...register("name[ar]", { required: true })}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col lg={6} xs={12}>
                    <div className="form-group required">
                      <h5>اسم التصنيف بالانجليزية</h5>
                      <div>
                        <input
                          type="text"
                          className="form-control form-outline"
                          placeholder="اسم التصنيف بالانجليزية"
                          {...register("name[en]", { required: true })}
                        />
                      </div>
                    </div>
                  </Col>

                  <Col lg={4} xs={12}>
                    <div className="form-group">
                      <button type="submit" className="btn btn-blue">
                        حفظ التعديلات
                      </button>
                    </div>
                  </Col>
                </Row>
              </Col>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditCategory;
